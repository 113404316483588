import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAp-IUX_xEyzzDp9w4vanTm7onrq42aqOU",
  authDomain: "variablfont-3bbe5.firebaseapp.com",
  projectId: "variablfont-3bbe5",
  storageBucket: "variablfont-3bbe5.appspot.com",
  messagingSenderId: "8187443404",
  appId: "1:8187443404:web:8728854b57bffc06032a69",
  measurementId: "G-TK4MEJR7HS"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
