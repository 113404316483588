import React from 'react'
import AddTypeForm from '../components/addType/AddTypeForm'

const PageAddType = () => {
  return (
    <>
      <AddTypeForm />
    </>
  )
}

export default PageAddType
